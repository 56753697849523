import React from 'react';
import './QRCodeLogin.css';
import { FaBars, FaUserCircle } from 'react-icons/fa';


const QRCodeLogin = () => {
  const qrValue = "https://example.com/login";  // The link for the QR code

  return (
    <div className="whatsapp-login">
      {/* Sidebar */}
      <div className="sidebar2">
        <div className="menu-icon">
          <FaBars />
        </div>
        <div className="bottom-section">
          <FaUserCircle className="profile-icon" />
        </div>
      </div>

      {/* Main Login Area */}
      <div className="login-area">
        <h1 className="title">Log in with Google</h1>
        <p className="instructions">
          Please login with your Google Account to start
        </p>
        
       
        <p className="footer">Need help? <a href="/dashboard">Click here</a>.</p>
      </div>

      <div>
        Pic
      </div>
    </div>
  );
};

export default QRCodeLogin;
