import React from 'react';
import './Sidebar.css';
import { FaUserCircle } from 'react-icons/fa'; // User icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // Menu icon
import { BiSearch } from 'react-icons/bi'; // Search icon

function Sidebar() {
  return (
    <div className="sidebar" style={{width:'300px', backgroundColor:'#111b21'}}>
      <div className="sidebar-header">
       
        <div className="sidebar-actions">
          <BiSearch size={20} />
          <BsThreeDotsVertical size={20} />
        </div>
      </div>
      <div className="sidebar-chats">
        <div className="chat">
          <p>Mohammed Lawal Abubakar</p>
          <small>Hey! What's up?</small>
        </div>
        <div className="chat">
          <p>Jane Smith</p>
          <small>Meeting at 3 PM.</small>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
