import React, { useState, useEffect, useRef } from 'react';
import { EditorState } from '@codemirror/state';
import { EditorView, basicSetup } from '@codemirror/basic-setup';
import { javascript } from '@codemirror/lang-javascript';
import Header from './Header';
import './ChatArea.css';
import './CodeEditor.css';
const MainContent = ({ activeMenu }) => {


  return (
    <div className="chat-area">
      <Header />
      
    </div>
  );
}

export default MainContent;
