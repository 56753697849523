import React, { useState } from 'react';
import { FaBars, FaComments, FaRegFileAlt, FaPhone, FaUserCircle, FaCog, FaChalkboardTeacher, FaClipboardList, FaTrophy, FaBook, FaUsers } from 'react-icons/fa';
import './sideMenu.css';

const SideMenu = ({ onMenuSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  // Menu items for the main section
  const mainMenuItems = [
    { id: 'courses', icon: <FaComments />, label: 'My Courses' },
    { id: 'live-class', icon: <FaChalkboardTeacher />, label: 'Live Class' },
    { id: 'assignments', icon: <FaClipboardList />, label: 'Assignments' },
    { id: 'board', icon: <FaUsers />, label: 'Board' },
    { id: 'quizzes', icon: <FaBook />, label: 'Quizzes' },
    { id: 'grade-book', icon: <FaRegFileAlt />, label: 'Grade Book' },
    { id: 'leaders-board', icon: <FaTrophy />, label: "Leader's Board" },
    { id: 'status', icon: <FaPhone />, label: 'Status' }, // You can replace this with another icon, depending on context
  ];

  // Bottom menu items
  const bottomMenuItems = [
    { id: 'settings', icon: <FaCog />, label: 'Settings' },
    { id: 'profile', icon: <FaUserCircle />, label: 'Profile' },
  ];

  return (
    <div className={`menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div
        className="menu-toggle"
        onClick={toggleMenu}
        role="button"
        tabIndex="0"
        aria-label="Toggle menu"
      >
        <FaBars />
      </div>
      <ul className="menu-list">
        {mainMenuItems.map((item) => (
          <li
            key={item.id}
            className="menu-item"
            onClick={() => onMenuSelect(item.id)}
            role="button"
            tabIndex="0"
            aria-label={`Select ${item.label}`}
          >
            <span className="icon">{item.icon}</span>
            {isExpanded && <span className="label">{item.label}</span>}
          </li>
        ))}
      </ul>
      <ul className="bottom-menu">
        {bottomMenuItems.map((item) => (
          <li
            key={item.id}
            className="menu-item"
            onClick={() => onMenuSelect(item.id)}
            role="button"
            tabIndex="0"
            aria-label={`Select ${item.label}`}
          >
            <span className="icon">{item.icon}</span>
            {isExpanded && <span className="label">{item.label}</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
