import React, { useState } from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import SideMenu from './components/SideMenu';
import QRCodeLogin from './components/QRCodeLogin';

function App() {
  const [activeMenu, setActiveMenu] = useState('chats');

  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Route for /login */}
          <Route path="/login" element={<QRCodeLogin />} />

          {/* Route for /dashboard */}
          <Route path="/dashboard" element={
            <>
              <SideMenu onMenuSelect={setActiveMenu} />
              <Sidebar activeMenu={activeMenu} />
              <MainContent activeMenu={activeMenu} />
            </>
          } />

          {/* Default route for other pages */}
          <Route path="/" element={<QRCodeLogin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
