import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { BsThreeDotsVertical, BsTelephone, BsCameraVideo } from 'react-icons/bs';
import './Header.css';

function Header() {
  return (
    <div className="header">
      <FaUserCircle size={30} />
      <div className="header-info">
        <h4>John Doe</h4>
        <small>Online</small>
      </div>
      <div className="header-actions">
        <BsTelephone size={20} />
        <BsCameraVideo size={20} />
        <BsThreeDotsVertical size={20} />
      </div>
    </div>
  );
}

export default Header;
